import React from "react";
import "./loading.scss";

const Loading = ({ dark = false }) => {
  return (
    <div className="a-loading">
      <div className={`a-loading__lds-ring ${dark ? "just-dark" : ""}`}>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
  )
}

export default Loading;